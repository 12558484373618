<template>
    <div class="container w-sm-100 w-xs-100 px-5" id="review-wrapper">
        <div class="row">
            <div v-if="showReviewDetails"
                 class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
                <h3 class="mt-2 mb-3">
                    Review your transfer details
                </h3>

                <div class="review-detail-form">

                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">

<!--                            <p class="review-element review-element-large mb-1">-->
<!--                                <small class="review-element-name">You send</small>-->
<!--                                <span class="review-element-value review-element-h3 h3">-->
<!--                                    {{formatFiatPrice(sendAmount)}} {{sendCurrency}}-->
<!--                                </span>-->
<!--                            </p>-->
                            <p class="review-element review-element-large mb-1">
                                <small class="review-element-name">
                                    Recipient gets exactly
                                </small>
                                <span class="review-element-value review-element-h3 h3">
                                    {{formatFiatPrice(receiveAmount)}} {{receiveCurrency}}
                                </span>
                            </p>
                            <p class="review-element review-element-large mb-1">
                                <small class="review-element-name">
                                    Crypto amount to pay <strong>(indicative)</strong>
                                </small>
                                <span class="review-element-value review-element-h3 h3">
                                    {{cryptoAmountToPay}}
                                </span>
                            </p>
                            <p class="review-element" style="margin-top:-12px">
                                <small class="review-element-name" style="font-size: 13px">
                                    including fees of
                                    <!--<br>
                                    <small>Guaranteed for xx hours/indicative</small>-->
                                </small>
                                <span class="review-element-value text-primary" style="font-size: 13px">
                                    {{formatFiatPrice(transferFee)}} {{receiveCurrency}}
                                </span>
                            </p>
                            <p class="review-element mb-1" style="margin-top:-10px">
                                <small class="review-element-name">
                                    Exchange rate
                                    <!--<br>
                                    <small>Guaranteed for xx hours/indicative</small>-->
                                </small>
                                <span class="review-element-value text-primary">
                                    1 {{exchangeRateValueFormat}}
                                    <!-- 1 {{cryptoCurrency}} = {{formatFiatPrice(cryptoExchangeRate)}} {{sendCurrency}} -->
                                </span>
                            </p>

<!--                            <p class="review-element mb-1">-->
<!--                                <small class="review-element-name">-->
<!--                                    Total fees-->
<!--                                </small>-->
<!--                                <span class="review-element-value text-primary">-->
<!--                                    {{formatFiatPrice(transferFee)}} {{sendCurrency}}-->
<!--                                </span>-->
<!--                            </p>-->
<!--                            <p class="review-element mb-1">-->
<!--                                <small class="review-element-name">-->
<!--                                    Amount we'll convert-->
<!--                                </small>-->
<!--                                <span class="review-element-value text-primary">-->
<!--                                    {{formatFiatPrice(convertedAmount)}} {{sendCurrency}}-->
<!--                                </span>-->
<!--                            </p>-->



<!--                            <p class="review-element mb-1">-->
<!--                                <small class="review-element-name">-->
<!--                                    Exchange rate-->
<!--                                </small>-->
<!--                                <span class="review-element-value text-primary">-->
<!--                                    1 {{sendCurrency}} = {{formatFiatPrice(fiatExchangeRate)}} {{receiveCurrency}}-->
<!--                                </span>-->
<!--                            </p>-->

                            <div class="pt-1 pb-2">
                                <hr>
                            </div>

                            <h5 class="mb-2">
                                Recipient details
                            </h5>

                            <p class="review-element mb-1">
                                <small class="review-element-name">
                                    Name
                                </small>
                                <span class="review-element-value text-primary">
                                    {{recipientName}}
                                </span>
                            </p>

<!--                            <p class="review-element mb-1">-->
<!--                                <small class="review-element-name">-->
<!--                                    Email-->
<!--                                </small>-->
<!--                                <span class="review-element-value text-primary">-->
<!--                                    {{recipientEmail}}-->
<!--                                </span>-->
<!--                            </p>-->

                            <p class="review-element mb-1">
                              <small class="review-element-name">
                                Bank Name
                              </small>
                              <span class="review-element-value text-primary">
                                      {{recipientBankName}}
                              </span>
                            </p>

                            <p class="review-element mb-1">
                                <small class="review-element-name">
                                    Bank account number
                                </small>
                                <span class="review-element-value text-primary">
                                    {{recipientBankAccountNumber}}
                                </span>
                            </p>

                            <p class="review-element mb-1">
                                <small class="review-element-name">
                                    BIC/SWIFT code
                                </small>
                                <span class="review-element-value text-primary">
                                    {{recipientSWIFTCode}}
                                </span>
                            </p>


                            <!--                            <div class="row"-->
                            <!--                                 v-if="paymentDetailsGetter && paymentDetailsGetter.recipientInfo && !paymentDetailsGetter.recipientInfo.recipientBankDetails">-->
                            <!--                                <div class="col-xs-12 col-md-12 col-lg-12 col-sm-12">-->
                            <!--                                    An email will be sent to recipient's email for them to provide their bank details.-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <!--                            <div v-else>-->
                            <!--                                <div v-if="paymentDetailsGetter &&-->
                            <!--                                paymentDetailsGetter.recipientInfo &&-->
                            <!--                                paymentDetailsGetter.recipientInfo.recipientBankDetails &&-->
                            <!--                                paymentDetailsGetter.recipientInfo.recipientBankDetails.someone">-->
                            <!--                                    <p class="review-element mb-1">-->
                            <!--                                        <small class="review-element-name">-->
                            <!--                                            Bank account number-->
                            <!--                                        </small>-->
                            <!--                                        <span class="review-element-value text-primary">-->
                            <!--                                    {{recipientBankAccountNumber  && recipientBankAccountNumber}}-->
                            <!--                                </span>-->
                            <!--                                    </p>-->

                            <!--                                    <p class="review-element mb-1">-->
                            <!--                                        <small class="review-element-name">-->
                            <!--                                            BIC/SWIFT code-->
                            <!--                                        </small>-->
                            <!--                                        <span class="review-element-value text-primary">-->
                            <!--                                    {{recipientSWIFTCode && recipientSWIFTCode}}-->
                            <!--                                </span>-->
                            <!--                                    </p>-->
                            <!--                                </div>-->
                            <!--                                <div v-else-if="paymentDetailsGetter &&-->
                            <!--                                paymentDetailsGetter.recipientInfo &&-->
                            <!--                                paymentDetailsGetter.recipientInfo.recipientBankDetails &&-->
                            <!--                                paymentDetailsGetter.recipientInfo.recipientBankDetails.self">-->
                            <!--                                    <p class="review-element mb-1">-->
                            <!--                                        <small class="review-element-name">-->
                            <!--                                            Bank account number-->
                            <!--                                        </small>-->
                            <!--                                        <span class="review-element-value text-primary">-->
                            <!--                                    {{recipientBankAccountNumber  && recipientBankAccountNumber}}-->
                            <!--                                </span>-->
                            <!--                                    </p>-->

                            <!--                                    <p class="review-element mb-1">-->
                            <!--                                        <small class="review-element-name">-->
                            <!--                                            BIC/SWIFT code-->
                            <!--                                        </small>-->
                            <!--                                        <span class="review-element-value text-primary">-->
                            <!--                                    {{recipientSWIFTCode && recipientSWIFTCode}}-->
                            <!--                                </span>-->
                            <!--                                    </p>-->
                            <!--                                </div>-->

                            <!--                            </div>-->


                        </div>
                    </div>


                    <div class="pt-1 pb-2">
                        <!--<hr>-->
                        <p class="text-error text-center" v-if="reviewError">An error has occurred. Please try again or refresh the page.</p>
                    </div>

                    <div class="">
                        <button v-if="kycUrl.length > 0" class="btn btn-block btn-primary" v-show="tx_status && rcvr_status"
                                @click="submitReviewDetails()">Confirm and verify identity
                        </button>
                        <button v-else v-show="tx_status && rcvr_status" class="btn btn-block btn-primary" @click="submitReviewDetails()">Confirm and
                            Continue
                        </button>
                        <p class="p-2" :class="{'txAndRcvrStatus': !tx_status || !rcvr_status, 'failTxAndRcvr': tx_status && rcvr_status}">
                           <span class="p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#c73b2d" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                            </svg>
                            Oops! 
                            </span>This operation can not be processed. Please contact support us at <strong class="text-success">support@transcryptglobal.com</strong>
                        </p>
                    </div>

                  <p class="mt-2 text-center" v-if="showHelpButton">If you have trouble verifying your identity, please submit your identity document and a recent photo of yourself or selfie to
                    <a href="mailto:support@transcryptglobal.com?subject=Failed%20or%20canceled%20KYC">support@transcryptglobal.com</a>.</p>

                </div>
            </div>
            <div v-else class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
                <h2 class="text-center">Loading........</h2>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'review-details',
        data() {
            return {
                mode: process.env.VUE_APP_STAGING_ENV ? null : "production",
                sendAmount: "",
                sendCurrency: "",
                cryptoAmount: "",
                transferFee: "",
                convertedAmount: "",
                receiveAmount: "",
                receiveCurrency: "",
                cryptoExchangeRate: "",
                fiatExchangeRate: "",
                cryptoCurrency: "",

                reviewError: false,


                //recipient details
                recipientName: "",
                recipientEmail: "",
                recipientBankAccountNumber: "",
                recipientSWIFTCode: "",
                recipientBankName : "",

                kycUrl: "",

                showReviewDetails: false,

                debugConsole : false,
                showHelpButton : false,
                rcvr_status: true,
                tx_status: true
            }
        },
        watch: {
            $route() {

            }
        },
        computed: {
            ...mapGetters(["paymentDetailsGetter", "userDetailsGetter"]),
            cryptoAmountToPay() {
                let amountAndCcy = '';
                if(this.cryptoCurrency === "LNBC" && parseInt(this.cryptoAmount) === this.cryptoAmount) { // check int number
                    amountAndCcy = this.formatFiatPrice(this.cryptoAmount) + ' SATS';
                } else if(this.cryptoCurrency === "LNBC" && this.cryptoAmount === +this.cryptoAmount && this.cryptoAmount !== (this.cryptoAmount|0)) { // check float number
                    amountAndCcy = this.formatFiatPrice(this.cryptoAmount * 1e8) + ' SATS';
                } else {
                    amountAndCcy = ` ${this.cryptoAmount} ${this.cryptoCurrency}`;
                }
                return amountAndCcy;
            },
            exchangeRateValueFormat() {
                let exchangeStr = "";
                if(this.cryptoCurrency === "LNBC" && parseInt(this.cryptoExchangeRate) === 0) {
                    exchangeStr = `SATS = ${(this.cryptoExchangeRate)} ${this.sendCurrency}`
                } else if(this.cryptoCurrency === "LNBC" && parseInt(this.cryptoExchangeRate) !== 0) {
                    exchangeStr = `SATS = ${(this.cryptoExchangeRate / 1e8).toFixed(8)} ${this.sendCurrency}`
                } else {
                    exchangeStr = `${this.cryptoCurrency} = ${this.formatFiatPrice(this.cryptoExchangeRate)} ${this.sendCurrency}`
                }
                return exchangeStr;
            }
        },
        metaInfo: {
            title: 'TransCrypt - Review Details | Send Money Faster with Cryptocurrency',
        },
        mounted() {
          if(this.debugConsole)  console.debug("Review Details Mounted");

            if (window.localStorage.getItem('visited_authenteq') === 'true') {
                // TODO display some custom message
              // console.debug('user came back from authenteq without successful KYC, please HELP HIM! :D');
              this.showHelpButton = true;
            }
            else {
              // console.debug('visited_authenteq not set yet');
            }

            //for stepper
            let target = "review-info";
            this.$store.commit('UPDATE_STEPPER', target);

            //check if url has tx reference as query string
            let url = new URL(window.location)
            let txReference = url.searchParams.get('tx_ref_num');

            if(this.debugConsole) console.log("TxReference in URL", txReference);

            if(this.debugConsole) console.log("Payment Details Getter", this.paymentDetailsGetter);


            let payload = {};
            payload.txReference = txReference ? txReference : this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.txReference;
            payload.receiverKey = this.paymentDetailsGetter && this.paymentDetailsGetter.recipientInfo && this.paymentDetailsGetter.recipientInfo.receiverKey;

            this.getReviewTransaction(payload).then(res => {
                if(this.debugConsole) console.log("Get Review Transaction result with selected self recipient", res);

                if(this.paymentDetailsGetter)
                {
                    //check if KYC URL exist
                    this.kycUrl = this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.kycUrl;
                    if(this.debugConsole) console.log("KYC URL in review comp", this.kycUrl);

                    //get the data from vuex store via getters and display it.
                    let remittanceInfo = this.paymentDetailsGetter.remittanceInfo;
                    let recipientInfo = this.paymentDetailsGetter.recipientInfo;
                    let userDetails = this.userDetailsGetter;

                    if(this.debugConsole) console.log("Remittance Info", remittanceInfo);
                    if(this.debugConsole) console.log('Recipient Info', recipientInfo);
                    if(this.debugConsole) console.log("user details", userDetails);
                    //tx and rcvr status
                    this.rcvr_status = recipientInfo.recipientStatus;

                    this.sendAmount = remittanceInfo.sendAmount || 0;
                    this.sendCurrency = remittanceInfo.sendCurrency || "";
                    this.cryptoExchangeRate = remittanceInfo.cryptoExchangeRate || 0;
                    this.fiatExchangeRate = remittanceInfo.fiatExchangeRate || 0;
                    this.transferFee = remittanceInfo.transferFee || 0;
                    this.convertedAmount = remittanceInfo.finalSendAmount || 0;
                    this.receiveAmount = remittanceInfo.receiveAmount || "";
                    this.receiveCurrency = remittanceInfo.receiveCurrency || "";
                    this.cryptoCurrency = remittanceInfo.cryptoCurrency || "";
                    // this.cryptoAmount = this.cryptoCurrency !== 'USDT' ? this.formatCryptoPrice(remittanceInfo.cryptoAmount) : this.formatFiatPrice(remittanceInfo.cryptoAmount) || 0;
                    this.cryptoAmount = remittanceInfo.cryptoAmount || 0;

                    this.recipientName = recipientInfo.recipientBankAccountName || "";
                    this.recipientEmail = recipientInfo.recipientEmail || "";
                    this.recipientBankName = recipientInfo.recipientBankName || "";

                    this.recipientBankAccountNumber = recipientInfo.recipientBankAccountNumber || "";
                    this.recipientSWIFTCode = recipientInfo.recipientBankSwiftCode || "";

                    this.showReviewDetails = true;
                }

            }).catch(err => {
                if(this.debugConsole)console.log("Get Review Transaction error ", err.response);
            })

            // if (txReference) {
            //     let payload = {};
            //     payload.txReference = txReference;
            //     payload.receiverKey = this.paymentDetailsGetter && this.paymentDetailsGetter.recipientInfo && this.paymentDetailsGetter.recipientInfo.receiverKey;
            //
            //     console.log("Payment Details Getter", this.paymentDetailsGetter);
            //
            //     this.getReviewTransaction(payload).then(res => {
            //         console.log("Get Review Transaction result with selected self recipient", res);
            //
            //         if(this.paymentDetailsGetter)
            //         {
            //             //check if KYC URL exist
            //             this.kycUrl = this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.kycUrl;
            //             console.log("KYC URL in review comp", this.kycUrl);
            //
            //             //get the data from vuex store via getters and display it.
            //             let remittanceInfo = this.paymentDetailsGetter.remittanceInfo;
            //             let recipientInfo = this.paymentDetailsGetter.recipientInfo;
            //             let userDetails = this.userDetailsGetter;
            //
            //             console.log("Remittance Info", remittanceInfo);
            //             console.log('Recipient Info', recipientInfo);
            //             console.log("user details", userDetails);
            //
            //             this.sendAmount = remittanceInfo.sendAmount || 0;
            //             this.sendCurrency = remittanceInfo.sendCurrency || "";
            //             this.cryptoAmount = remittanceInfo.cryptoAmount || 0;
            //             this.cryptoExchangeRate = remittanceInfo.cryptoExchangeRate || 0;
            //             this.fiatExchangeRate = remittanceInfo.fiatExchangeRate || 0;
            //             this.transferFee = remittanceInfo.transferFee || 0;
            //             this.convertedAmount = remittanceInfo.finalSendAmount || 0;
            //             this.receiveAmount = remittanceInfo.receiveAmount || "";
            //             this.receiveCurrency = remittanceInfo.receiveCurrency || "";
            //
            //             this.recipientName = recipientInfo.recipientFullName || "";
            //             this.recipientEmail = recipientInfo.recipientEmail || "";
            //
            //             this.recipientBankAccountNumber = recipientInfo.recipientBankAccountNumber || "";
            //             this.recipientSWIFTCode = recipientInfo.recipientBankSwiftCode || "";
            //
            //             this.showReviewDetails = true;
            //
            //         }
            //
            //
            //     }).catch(err => {
            //         console.log("Get Review Transaction error ", err.response);
            //     })
            //
            // }
            // else
            // {
            //     if (this.paymentDetailsGetter) {
            //
            //         console.log("Payment Details Getter", this.paymentDetailsGetter);
            //         // let payload = {};
            //         // payload.txReference = this.paymentDetailsGetter.recipientInfo && this.paymentDetailsGetter.recipientInfo.receiverKey;
            //         //
            //         // this.getReviewTransaction(payload).then(res => {
            //         //     console.log("Get Review Transaction result with selected self recipient", res);
            //         //
            //         //
            //         // }).catch(err => {
            //         //     console.log("Get Review Transaction error with selected self recipient", err);
            //         // })
            //
            //         //check if KYC URL exist
            //         this.kycUrl = this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.kycUrl;
            //         console.log("KYC URL in review comp", this.kycUrl);
            //
            //         //get the data from vuex store via getters and display it.
            //         let remittanceInfo = this.paymentDetailsGetter.remittanceInfo;
            //         let recipientInfo = this.paymentDetailsGetter.recipientInfo;
            //         let userDetails = this.userDetailsGetter;
            //
            //         console.log("Remittance Info", remittanceInfo);
            //         console.log('Recipient Info', recipientInfo);
            //         console.log("user details", userDetails);
            //
            //         this.sendAmount = remittanceInfo.sendAmount || 0;
            //         this.sendCurrency = remittanceInfo.sendCurrency || "";
            //         this.cryptoAmount = remittanceInfo.cryptoAmount || 0;
            //         this.cryptoExchangeRate = remittanceInfo.cryptoExchangeRate || 0;
            //         this.fiatExchangeRate = remittanceInfo.fiatExchangeRate || 0;
            //         this.transferFee = remittanceInfo.transferFee || 0;
            //         this.convertedAmount = remittanceInfo.finalSendAmount || 0;
            //         this.receiveAmount = remittanceInfo.receiveAmount || "";
            //         this.receiveCurrency = remittanceInfo.receiveCurrency || "";
            //
            //         this.recipientName = recipientInfo.recipientFullName || "";
            //         this.recipientEmail = recipientInfo.recipientEmail || "";
            //
            //         this.recipientBankAccountNumber = recipientInfo.recipientBankAccountNumber || "";
            //         this.recipientSWIFTCode = recipientInfo.recipientBankSwiftCode || "";
            //
            //         this.showReviewDetails = true;
            //
            //
            //     }
            // }




        },

        methods: {
            ...mapActions(["getPaymentRequest", "getReviewTransaction", "authLogout"]),

            submitReviewDetails() {
                 // set loader
                this.$vs.loading({
                    container: '#review-wrapper',
                    scale: 0.8,
                    color: 'success'
                });

                if (this.kycUrl.length > 0) {
                    //if kycUrl exist, go to Authenteq URL
                  window.localStorage.setItem('visited_authenteq', 'true');
                  // console.debug('visited_authenteq set to TRUE', window.localStorage.getItem('visited_authenteq'));


                  window.top.location.href = this.kycUrl;
                }
                //if kycUrl does not exist, go to payment form
                else {
                    let payload = {};
                    payload.txReference = this.paymentDetailsGetter && this.paymentDetailsGetter.remittanceInfo && this.paymentDetailsGetter.remittanceInfo.txReference;
                        this.getPaymentRequest(payload).then(res => {

                            if(this.debugConsole)  console.log("Payment Request result in review details", res.data);

                        if (res.data && res.data.hosted_url) {
                            this.reviewError = false;

                            this.tx_status = res.data.tx_status;
                            // unblock user by support
                            if(res.data.user_status) {
                                this.tx_status = true;
                            }
                            // close loader and route push to review page
                            this.$vs.loading.close('#review-wrapper > .con-vs-loading');

                            this.tx_status && this.$router.push('/transfer/make-payment');
                        }
                        else
                        {
                            this.tx_status = res.data.tx_status;
                            // refresh msg only show when everything ok but hosted url not available.
                            if(this.tx_status) {
                                this.reviewError = true;
                            }
                            // close loader and route push to review page
                            this.$vs.loading.close('#review-wrapper > .con-vs-loading');
                        }

                    }).catch(err => {
                            // close loader if error occur
                            this.$vs.loading.close('#review-wrapper > .con-vs-loading');
                            if(this.debugConsole)  console.log("Error in payment request in review details", err.response);
                    })
                }

            }
        },
        beforeDestroy() {
            // redirect to login page if user blocked
            if(!this.tx_status) {
                this.authLogout().then(res => {
                    if (this.debugConsole) console.log("logout the current logged in user", res.data);
                    this.$store.commit("USER_LOGOUT");
                    this.$router.push('/account/login');

                    }).catch(err => {
                    if (this.debugConsole) console.log("Error in logut the current loggedi in user", err);
                })
            }
        }
    }
</script>